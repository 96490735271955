<template>
    <v-container fluid>
        

        <v-layout row :style="$vuetify.breakpoint.mdAndDown ? {height: '60px'} : {height: '100px'}" :class="$route.name === 'message' ? 'custom_message_wrapper' : ''">
            <!-- <v-app-bar  height="100" app dense elevate-on-scroll elevation="5" flat color="#ffffff" class="hidden-md-and-down navbar mx-auto"></v-app-bar> -->
            <v-app-bar height="100" :class="$route.name === 'message' ? 'custom_message' : ''" app elevation="3" :color="$vuetify.theme.dark ? '#292c2c' : '#FFF'" class="hidden-md-and-down navbar mx-auto">
                <v-container style="max-width: 1261px">
                    <v-layout row style="/*justify-content: flex-end;*/">
                        <v-toolbar-title style="min-width:270px; margin: 0 25px; /*flex: auto;*/">
                            <router-link class="height" :to="'/?lang='+lang">
                                <v-img contain :max-height="isLang() ? '75' : '65'" max-width="360" :src="!isLang() ? 'https://alkafeel.net/src/svg/'+ar_logo : 'https://alkafeel.net/'+lang_src+'/'+logo" alt=""></v-img>
                            </router-link>
                        </v-toolbar-title>
                        <v-toolbar-items style="align-items: center;">
                            <template v-if="loading">
                                <v-skeleton-loader style="margin: 20px;" v-bind="attrs" type="button"></v-skeleton-loader>
                                <v-skeleton-loader style="margin: 20px;" v-bind="attrs" type="button"></v-skeleton-loader>
                                <v-skeleton-loader style="margin: 20px;" v-bind="attrs" type="button"></v-skeleton-loader>
                                <v-skeleton-loader style="margin: 20px;" v-bind="attrs" type="button"></v-skeleton-loader>
                                <v-skeleton-loader style="margin: 20px;" v-bind="attrs" type="button"></v-skeleton-loader>
                                <v-skeleton-loader style="margin: 20px;" v-bind="attrs" type="button"></v-skeleton-loader>
                            </template>
                            <template v-else >
                                <div v-for="item in nav.slice(0, 6)" :key="item">  
                                    <template >  
                                        <v-menu offset-y offset-overflow left open-on-hover open-delay="200">
                                            
                                            <template v-slot:activator="{ on, attrs }" v-if="item.visible == 1">
                                                <!-- if arabic -->
                                                <template v-if="!isLang()">
                                                    <v-btn :to="item.router !== '0' ? item.to : ''" :href="item.router === '0' ? item.url : ''" elevation="0" class="nav_btn" color="rgba(0,0,0,0)" :title="item.title" v-bind="attrs" v-on="on">
                                                        <span class="text-light sp">{{ item.title }}</span>
                                                        <span v-if="item.sub" style="content: url(/assets/img/arrow_down.svg); width:10px; margin: 0 8px; vertical-align: bottom; cursor:pointer"></span>
                                                    </v-btn>
                                                </template>
                                                <!-- if is lang -->
                                                <template v-else>
                                                    <v-btn :to="item.router !== '0' ? (item.to ? item.to+'?lang='+lang : '') : ''" :href="item.router === '0' ? (item.url ? item.url+'?lang='+lang : '') : ''" elevation="0" class="nav_btn" color="rgba(0,0,0,0)" v-bind="attrs" v-on="on" v-if="item.aronly == 0">
                                                        <span class="text-light sp">{{ $t(item.word) }}</span>
                                                        <span v-if="item.sub" style="content: url(/assets/img/arrow_down.svg); width:10px; margin: 0 8px; vertical-align: bottom; cursor:pointer"></span>
                                                    </v-btn>
                                                </template>
                                            </template>
                                            
                                            <v-list class="messiri_fonted" :color="$vuetify.theme.dark ? 'rgba(255,255,255,.8)' : 'rgba(0,0,0,.7)'" v-if="item.sub" :width="item.width">
                                                
                                                    <template v-for="(t, index) in JSON.parse(item.sub)" >
                                                        <!-- one col subs -->
                                                        <template v-if="item.width < 370 && t.visible == 1">
                                                            <template v-if="!isLang()">
                                                                <v-list-item class="list_height" :key="index">
                                                                    <v-list-item-title>

                                                                        <template v-if="!t.router">
                                                                            <a class="whiteText sub_nav" :href="t.url"><span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> {{ t.title }} </a>
                                                                        </template>
                                                                        <template v-else>
                                                                            <router-link class="whiteText sub_nav" :to="t.to" ><span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> {{ t.title }} </router-link>
                                                                        </template>
                                                                        
                                                                    </v-list-item-title>
                                                                </v-list-item>
                                                            </template>
                                                            <template v-else-if="t.aronly == 0">
                                                                <v-list-item class="list_height" :key="index">
                                                                    <v-list-item-title>

                                                                        <template v-if="!t.router">
                                                                            <a class="whiteText sub_nav" :href="t.url+'?lang='+lang"> <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> {{ $t(t.word)}} </a>
                                                                        </template>
                                                                        <template v-else>
                                                                            <router-link class="whiteText sub_nav" :to="t.to+'?lang='+lang" ><span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> {{ $t(t.word) }} </router-link>
                                                                        </template>

                                                                    </v-list-item-title>
                                                                </v-list-item>
                                                            </template>
                                                        </template>
                                                        
                                                        <!-- two col subs -->
                                                        <template v-if="item.width > 370 && item.width < 790">
                                                            <!-- if arabic -->
                                                            <template v-if="!isLang()">
                                                                <v-list-item class="list_height" v-if="index%2 == 0" :key="index">
                                                                    <v-flex lg6> 


                                                                        <template v-if="!t.router">
                                                                            <a class="whiteText sub_nav" :href="t.url" v-if="t.visible == 1">
                                                                                <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> 
                                                                                {{ t.title }}
                                                                            </a> 
                                                                        </template> 
                                                                        <template v-else>
                                                                            <router-link class="whiteText sub_nav" :to="t.to" v-if="t.visible == 1">
                                                                                <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> 
                                                                                {{ t.title }} 
                                                                            </router-link>
                                                                        </template>
                                                                        
                                                                    </v-flex>
                                                                    <v-flex lg6 v-if="JSON.parse(item.sub)[index+1] && JSON.parse(item.sub)[index+1].visible == 1"> 


                                                                        <template v-if="!JSON.parse(item.sub)[index+1].router">
                                                                            <a class="whiteText sub_nav" :href="JSON.parse(item.sub)[index+1].url">
                                                                                <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> 
                                                                                {{ JSON.parse(item.sub)[index+1].title }}
                                                                            </a>
                                                                        </template>
                                                                        <template v-else>
                                                                            <router-link class="whiteText sub_nav" :to="JSON.parse(item.sub)[index+1].to">
                                                                                <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> 
                                                                                {{ JSON.parse(item.sub)[index+1].title }}
                                                                            </router-link>
                                                                        </template>

                                                                    </v-flex>
                                                                </v-list-item>
                                                            </template>
                                                            <!-- if is lang -->
                                                            <template v-else-if="t.aronly == 0">
                                                                <v-list-item class="list_height" v-if="index%2 == 0" :key="index">
                                                                    <v-flex lg6> 

                                                                        <template v-if="!t.router">
                                                                            <a class="whiteText sub_nav" :href="t.url+'?lang='+lang" v-if="t.visible == 1">
                                                                                <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> 
                                                                                {{ $t(t.word) }}
                                                                            </a> 
                                                                        </template>
                                                                        <template v-else>
                                                                            <router-link class="whiteText sub_nav" :to="t.to+'?lang='+lang" v-if="t.visible == 1">
                                                                                <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> 
                                                                                {{ $t(t.word) }}
                                                                            </router-link>
                                                                        </template>

                                                                    </v-flex>
                                                                    <v-flex lg6 v-if="JSON.parse(item.sub)[index+1] && JSON.parse(item.sub)[index+1].visible == 1"> 

                                                                        <template v-if="!JSON.parse(item.sub)[index+1].router">
                                                                            <a class="whiteText sub_nav" :href="JSON.parse(item.sub)[index+1].url+'?lang='+lang">
                                                                                <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> 
                                                                                {{ $t(JSON.parse(item.sub)[index+1].word) }}
                                                                            </a>
                                                                        </template>
                                                                        <template v-else>
                                                                            <router-link class="whiteText sub_nav" :to="JSON.parse(item.sub)[index+1].to+'?lang='+lang" >
                                                                                <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> 
                                                                                {{ $t(JSON.parse(item.sub)[index+1].word) }}
                                                                            </router-link>
                                                                        </template>
                                                                        
                                                                    </v-flex>
                                                                </v-list-item>
                                                            </template> 

                                                        </template>

                                                        <!-- three col subs -->
                                                        <template v-if="item.width > 790">
                                                            <v-list-item class="list_height" v-if="index%3 == 0" :key="index">
                                                                <v-flex lg4> 

                                                                    <template v-if="!t.router">
                                                                        <a class="whiteText sub_nav" target="_blank" :href="t.url" v-if="t.visible == 1">
                                                                            <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> 
                                                                            {{ t.title }}
                                                                        </a> 
                                                                    </template>
                                                                    <template v-else>
                                                                        <router-link class="whiteText sub_nav" :to="t.to+'?lang='+lang" v-if="t.visible == 1">
                                                                            <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> 
                                                                            {{ t.title }}
                                                                        </router-link>
                                                                    </template>
                                                                    
                                                                </v-flex>
                                                                <v-flex lg4 v-if="JSON.parse(item.sub)[index+1] && JSON.parse(item.sub)[index+1].visible == 1"> 

                                                                    <template v-if="!JSON.parse(item.sub)[index+1].router">
                                                                        <a class="whiteText sub_nav" target="_blank" :href="JSON.parse(item.sub)[index+1].url">
                                                                            <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> 
                                                                            {{ JSON.parse(item.sub)[index+1].title }}
                                                                        </a>
                                                                    </template>
                                                                    <template v-else>
                                                                        <router-link class="whiteText sub_nav" :to="JSON.parse(item.sub)[index+1].to" >
                                                                            <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> 
                                                                            {{ JSON.parse(item.sub)[index+1].title }}
                                                                        </router-link>
                                                                    </template>

                                                                </v-flex>
                                                                <v-flex lg4 v-if="JSON.parse(item.sub)[index+2] && JSON.parse(item.sub)[index+2].visible == 1"> 

                                                                    <template v-if="!JSON.parse(item.sub)[index+2].router">
                                                                        <a class="whiteText sub_nav" target="_blank" :href="JSON.parse(item.sub)[index+2].url">
                                                                            <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> 
                                                                            {{ JSON.parse(item.sub)[index+2].title }}
                                                                        </a>
                                                                    </template>
                                                                    <template v-else>
                                                                        <router-link class="whiteText sub_nav" :to="JSON.parse(item.sub)[index+2].to" >
                                                                            <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> 
                                                                            {{ JSON.parse(item.sub)[index+2].title }}
                                                                        </router-link>
                                                                    </template>
                                                                    
                                                                </v-flex>
                                                            </v-list-item>

                                                        </template>
                                                    </template>

                                            </v-list>

                                        </v-menu>
                                    </template>

                                    
                                    <!-- <template v-else>
                                        <template v-if="!isLang()">
                                                <template v-if="item.visible == 1">
                                                    <v-btn elevation="0" @click="relatedOverlay = !relatedOverlay" style="margin-top: 10px;" class="nav_btn" color="rgba(0,0,0,0)" :title="item.title" v-bind="attrs" v-on="on">
                                                        <a class="text-light sp" :href="item.url"> {{ item.title }} </a>
                                                        <v-icon v-if="item.sub"> mdi-chevron-down </v-icon>
                                                    </v-btn>
                                                    
                                                </template>
                                        </template>
                                    </template> -->

                                </div>

                                <!-- overlay lang icon -->
                                <v-list-item class="list_height" style="position: absolute;top: 35%;" :style="dir === 'rtl' ? 'left: 5px;' : 'right: 5px;'" >
                                    <v-list-item-title>
                                        <div class="serv" @click="langsOverlay = !langsOverlay">
                                            <svg viewBox="0 0 44 48"><use xlink:href="/assets/img/services/lang2.svg#view"></use></svg>
                                        </div>
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item class="list_height d-toggle" style="position: absolute;top: 35%;" :style="dir === 'rtl' ? 'left: 50px;' : 'right: 50px;'" >
                                    <v-list-item-title>

                                        <v-btn width="20" class="hidden-sm-and-down mx-3 mt-n2" icon @click="toggle_dark_mode()" >
                                            <div class="togg_btn2">
                                                <svg v-if="$vuetify.theme.dark" viewBox="0 0 20 20"><use xlink:href="/assets/img/pray_icons/mid-n.svg#mid-n"></use></svg>
                                                <svg v-else viewBox="0 0 44 48" ><use xlink:href="/assets/img/pray_icons/sun.svg#sun"></use></svg>
                                            </div>
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>

                            </template>
                        </v-toolbar-items>
                    </v-layout>
                </v-container>
            </v-app-bar>

            
            
            <v-app-bar style="/*background-color:#fff !important;*/" app elevation="2"  class="hidden-lg-and-up navbar_m mx-auto">
                <v-toolbar-title class="height">
                    <router-link class="height" :to="'/?lang='+lang">
                        <v-img dark contain height="50" :max-width="isLang() ? '200' : ''" :position="dir === 'rtl' ? 'right center' : 'left center'" :src="!isLang() ? 'https://alkafeel.net/src/svg/'+ar_logo : 'https://alkafeel.net/'+lang_src+'/'+logo" alt=""></v-img>
                    </router-link>
                </v-toolbar-title>
                <v-spacer></v-spacer>


                <v-app-bar-nav-icon @click.stop="drawer = !drawer">
                    <div style="fill:#b5b5b5; width:30px; margin-top: 5px"><svg viewBox="0 0 35 25"><use xlink:href="/assets/img/burger_menu.svg#view"></use></svg></div>
                </v-app-bar-nav-icon>

                <v-navigation-drawer style="padding: 20px;" :style="$vuetify.theme.dark ? 'background-color:#292c2c' : ''" app class="drawer" color="rgba(255,255,255,1)" temporary disable-resize-watcher v-model="drawer" absolute :right="dir === 'rtl' ? true : false">
                    <v-list nav dense>
                        <v-list-item-group v-model="group" active-class="text--accent-4" >

                            <v-list-item-title style="margin-bottom: 20px">
                                <v-img contain :height="isLang() ? '30' : '50'" :position="dir === 'rtl' ? 'right center' : 'left center'" alt="" :src="!isLang() ? 'https://alkafeel.net/src/svg/'+ar_logo : 'https://alkafeel.net/'+lang_src+'/'+logo">
                                    <div class="fill-height" :style="dir === 'rtl' ? 'text-align: left' : 'text-align: right'">
                                        <img height="20" src="/assets/img/t-mnue.svg" @click.stop="drawer = false">
                                    </div>
                                </v-img>
                            </v-list-item-title>

                            <template v-for="item in nav">
                                <template v-if="!isLang()">
                                    <!-- :append-icon="item.sub ? ' mdi-chevron-down' : ''" -->
                                    <v-list-group :append-icon="false" :value="false" :key="item">
                                        <template v-slot:activator>
                                            <v-list-item-title v-if="item.visible == 1" :title="item.title">

                                                <!-- <a class="navMColor navSize" :href="item.url" >{{ item.title }}</a> -->
                                                <template v-if="item.router == 0">
                                                    <a class="navMColor navSize" :href="item.url" >{{ item.title }}</a>
                                                </template>
                                                <template v-else>
                                                    <router-link class="navMColor navSize" :to="item.to">{{ item.title }}</router-link>
                                                </template>

                                            </v-list-item-title>
                                        </template>

                                        <v-list-group class="messiri_fonted" :append-icon="false" v-for="(t, index) in JSON.parse(item.sub)" :key="index">
                                            <template v-slot:activator>
                                                <div v-if="item.sub">
                                                    <!-- <a class="navMColor sub_nav_m" :href="t.url" v-if="t.visible == 1"> 
                                                        <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> 
                                                        {{ t.title }} 
                                                    </a> -->
                                                    <template v-if="!t.router">
                                                        <a class="navMColor sub_nav_m" :href="t.url" v-if="t.visible == 1"> 
                                                            <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> 
                                                            {{ t.title }} 
                                                        </a>
                                                    </template>
                                                    <template v-else>
                                                        <router-link class="navMColor sub_nav_m" :to="t.to" v-if="t.visible == 1">
                                                            <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> 
                                                            {{ t.title }}
                                                        </router-link>
                                                    </template>
                                                                
                                                </div>
                                            </template>
                                        </v-list-group>

                                        <span v-if="item.sub" slot="appendIcon" style="content: url(/assets/img/arrow_down.svg); width:10px; margin: 0 8px; vertical-align: bottom; cursor:pointer"></span>
                                    </v-list-group>
                                </template>

                                <template v-else-if="item.aronly == 0">
                                    <!-- :append-icon="item.sub ? ' mdi-chevron-down' : ''" -->
                                    <v-list-group :append-icon="false" :value="false" :key="item">
                                        <template v-slot:activator>
                                            <!-- <v-list-item-title v-if="item.visible == 1" :title="$t(item.word)">
                                                <a class="navMColor navSize" :href="item.url+'?lang='+lang" >{{ $t(item.word) }}</a>
                                            </v-list-item-title> -->
                                            <v-list-item-title v-if="item.visible == 1" :title="$t(item.word)">

                                                <template v-if="item.router == 0">
                                                    <a class="navMColor navSize" :href="item.url ? item.url+'?lang='+lang : null" >{{ $t(item.word) }}</a>
                                                </template>
                                                <template v-else>
                                                    <router-link class="navMColor navSize" :to="item.to ? item.to+'?lang='+lang : null">{{ $t(item.word) }}</router-link>
                                                </template>
                                                
                                            </v-list-item-title>
                                        </template>

                                        <template v-for="t in JSON.parse(item.sub)" >
                                            <template v-if="t.aronly == 0">
                                                <v-list-group class="messiri_fonted" :append-icon="false" :key="t" v-if="item.sub && t.visible == 1">
                                                    <template v-slot:activator>
                                                        
                                                        <!-- <a class="navMColor sub_nav_m" :href="t.url+'?lang='+lang">
                                                            <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> 
                                                            {{ $t(t.word) }} 
                                                        </a> -->
                                                        <template v-if="!t.router">
                                                            <a class="navMColor sub_nav_m" :href="t.url+'?lang='+lang">
                                                                <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> 
                                                                {{ $t(t.word) }} 
                                                            </a>
                                                        </template>
                                                        <template v-else>
                                                            <router-link class="navMColor sub_nav_m" :to="t.to+'?lang='+lang" >
                                                                <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> 
                                                                {{ $t(t.word) }}
                                                            </router-link>
                                                        </template>

                                                    </template>
                                                </v-list-group>
                                            </template>
                                        </template>

                                        <span v-if="item.sub" slot="appendIcon" style="content: url(/assets/img/arrow_down.svg); width:10px; margin: 0 8px; vertical-align: bottom; cursor:pointer"></span>
                                    </v-list-group>
                                </template>
                            </template>


                            <!-- overlay lang icon -->
                            <v-list-item class="list_height" @click="langsOverlay = !langsOverlay">
                                <v-list-item-title>
                                    <a class="navMColor navSize" >{{$t('langs')}}</a>
                                </v-list-item-title>
                            </v-list-item>


                            <v-card dark flat>
                                <v-toolbar flat style="padding-top:10px">
                                    <v-switch :input-value="$vuetify.theme.dark" @change="toggle_dark_mode()" inset :label="$t('dark_mode')"></v-switch>
                                </v-toolbar>
                            </v-card>

                        </v-list-item-group>
                    </v-list>
                </v-navigation-drawer>

            </v-app-bar>

        </v-layout>


        <v-overlay absolute class="langs_overlay messiri_fonted" :value="langsOverlay" color="#f3f3f3" opacity="0.9" :key="index">

            <!-- <v-icon x-large light @click="langsOverlay = false">mdi-close-circle</v-icon> -->
            <span @click="langsOverlay = false" style="content: url('/assets/img/close.svg'); width:25px; margin: 0 8px; vertical-align: bottom; cursor:pointer"></span>
            <span class="footer_desc">{{$t('close')}}</span>

            <v-img src="/assets/img/footer_logo2.svg" height="65" class="mx-auto" width="319"></v-img>

            <div class="footer_desc mt-3 mb-14 text-center"> {{$t('alkafeel_title')}}  - {{ $t('alkafeel_desc') }}</div>

            <div class="messiri_fonted text-center" mb-6>
                <template v-for="lang in langs" >
                    <template v-if="lang['display'] == 1">
                        <span v-if="lang['dir'] == 1" class="lang_title" :key="lang" dir="rtl">
                            <span style="content: url('/assets/img/footer_title_ico.svg'); vertical-align: bottom;"></span>
                            
                            <a v-if="$route.name === 'new'" style="color: #5B5B5B;text-decoration: none; margin:0 10px" :href="$route.path+'?lang='+lang['code']">{{lang['name']}}</a>

                            <a v-else style="color: #5B5B5B;text-decoration: none; margin:0 10px" :href="'?lang='+lang['code']">{{lang['name']}}</a>
                        </span>
                    </template>
                </template>
            </div>

            <v-layout row class="messiri_fonted text-center" mt-6 mb-6 align-center style="padding-right: 22px; text-align:center">
                <template v-for="lang in langs" >
                    <template v-if="lang['display'] == 1">
                        <v-flex v-if="lang['dir'] == 2" class="lang_title" :key="lang" style="direction:rtl">
                            <a v-if="$route.name === 'new'" style="color: #5B5B5B;text-decoration: none; margin:0 10px" :href="$route.path+'?lang='+lang['code']">{{lang['name']}} </a>
                            <a v-else style="color: #5B5B5B;text-decoration: none; margin:0 10px" :href="'?lang='+lang['code']">{{lang['name']}} </a>
                            <span style="content: url('/assets/img/footer_title_ico.svg'); vertical-align: bottom;"></span>
                        </v-flex>
                    </template>
                </template>
            </v-layout>

            <hr style="color:#C4C4C4">



        </v-overlay>



    </v-container>
</template>
<script>
// import ah from '../../components/home/BreakingNews.vue';
// import axios from "axios";
// let nav   = 'https://alkafeel.net/main_bg/data/nav_main.json';
// let langs = 'https://alkafeel.net/main_bg/data/core_langs_main.json';

// const requestNav   = axios.get(nav);
// const requestlangs = axios.get(langs);

let endpoints = [
    'https://alkafeel.net/main_bg/data/nav_main.json',
    'https://alkafeel.net/main_bg/data/core_langs_main.json'

]
export default {
    // components : {
    //     BreakingNews: ah,
    // },
    data() {
        return {
            drawer: false,
            group: null,
            dialog: false,
            lang: 'ar',
            dir: 'rtl',
            logo: 'logo5.svg',
            ar_logo: 'logo5.svg',
            lang_src: 'uploads',
            nav: [],
            langs: [],
            loading: true,

            langsOverlay: false,
            dark_theme: 0
        }
    },
    watch: {
        group () {
            this.group = false
        },
        dark_theme() {
            if (this.$vuetify.theme.dark) { 
                this.ar_logo = 'logo6.svg';
                this.lang_src = 'src/svg';
            } else {
                this.ar_logo = 'logo5.svg';
                this.lang_src = 'uploads';
            }
        }
    },
    created () {
        this.initialize();
    },
    
    mounted () {
        const theme = localStorage.getItem("dark_theme");
        if (theme) {
            if (theme === "true") {
                this.$vuetify.theme.dark = true;
            } else {
                this.$vuetify.theme.dark = false;
            }
        } else if ( window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
            this.$vuetify.theme.dark = true;
            localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
        } else {
            this.$vuetify.theme.dark = false;
            localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
        }
    },
    methods: {
        toggle_dark_mode () {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
            localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
            this.dark_theme++
        },
        initialize() {
            this.$axios
                // .all([requestNav, requestlangs])
                .all(endpoints.map((endpoint) => this.$axios.get(endpoint)))
                .then(
                this.$axios.spread((...responses) => {
                    this.loading = false;
                    const responseNav   = responses[0].data;
                    const responseLangs = responses[1].data;

                    this.nav = responseNav;
                    this.langs = responseLangs;
                })
                
            )
            .catch();
            // console.log(this.$route.name, this.$route.path)
        },
        lang_dt (url) {
            if (url === undefined) {
                this.lang_ck ('ar');
                return;
            }
            this.lang_ck (url)
        },
        lang_ck (code) {
            this.langs.forEach(element => {
                if (element.code === code) {
                    this.lang = code;
                    this.logo = element.logo;
                    this.dir = element.dir == '1' ? 'rtl' : 'ltr';
                      this.$store.dispatch("UpdateLang",{lang:this.lang,dir:this.dir});
                    this.$vuetify.rtl = this.dir === 'rtl' ? true : false;
                }
            });
        },
        isLang () {
            if (this.lang !== 'ar') {
                return true;
            }
            return false;
        }

    },
    updated() {
        this.lang_dt(this.$route.query.lang);

        if (this.$vuetify.theme.dark) { 
            this.ar_logo = 'logo6.svg';
            this.lang_src = 'src/svg';
        }
    },
     
}
</script>
<style>
    .messiri_fonted * {
        font-family: 'el_messiri', arial;
    }
</style>
<style scoped>
    .langs_overlay >>> .v-overlay__content{
        width: 80%;
    }
    .whiteText {
        color: #FFF
    }
    .langs_overlay {
        position: fixed !important;
    }
    .footer_desc {
        color: #5B5B5B;
        font-size: 14px;
    }
    .lang_title {
        color: #5B5B5B; 
        vertical-align: top; 
        line-height: 40px; 
        font-size:30px;
        margin: 25px;
        min-width: 160px;
        display: inline-block;
    }
    @media (max-width: 600px) {
        .lang_title {
            vertical-align: top; 
            line-height: 20px; 
            font-size:20px;
            margin: 5px;
            min-width: 80px;
            display: inline-block;
        }
        .lang_title span {
            width: 18px;
        }
        .lang_title a {
            vertical-align: super;
        }
    }
    .serv {
        fill:#5E5E5E;
        width: 25px;
        height: 25px;
        margin: 0 auto 5px;
        cursor: pointer;
    }
    .togg_btn2 {
        fill:#5E5E5E;
        width: 25px;
        height: 25px;
    }
    .border {
        border-bottom:solid thin #D6D6D6
    }
    .drawer {
        position: absolute;
        top:0!important;
        right:0;
        left:0;
        width:100%!important;
        bottom: 0;
    }
    .navbar {
        height: auto!important;
        transition: .3s ease-in-out all;
    }
    .navbar a {
        text-decoration: none;
    }
    .navbar_m {
        transition: .3s ease-in-out all;
    }
    .navMColor {
        color:#5E5E5E
    }
    .navSize {
        font-size: 18px;
        text-decoration: none;
    }
    .v-list-group--active .v-list-group__header__append-icon span {
        transform: rotate(180deg);
    }
    .sub_nav_m {
        text-decoration: none;
        font-size: 15px;
    }
    .sp {
        letter-spacing: normal;
    }
    .list_height {
        min-height: 35px;
    }
    .sub_nav {
        text-decoration: none;
        font-size: 14px;
        line-height: 15px;
    }
    .nav_btn {
        box-shadow: none;
        font-size: 14px;
    }
    .nav_btn a {
        color: #5E5E5E;
    }
    .nav_btn:hover * {
        color: #B1BD52 !important;
    }
    .v-btn--active::before {
        opacity: 0!important;
    }
    .related_sub_item {
        width:12px; 
        height: 12px; 
        display: ruby-text-container;
        margin: 0 5px;
    }
    .related_links {
        color:#5B5B5B; 
        text-decoration: none;
        font-weight: 700;
        opacity: .7;
        font-size: 18px;
        line-height: 30px;
    }
    .height {
        width: 100%;
        height: 100%;
    }
</style>